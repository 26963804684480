.border-black,
table.all-borders th,
table.all-borders td {
  border: 1px solid black !important;
}

table .title {
  font-weight: 900 !important;
}

table th,
table td {
  vertical-align: middle !important;
}

.border-left-black {
  border-left: 1px solid black !important;
}

.border-right-black {
  border-right: 1px solid black !important;
}

.border-bottom-black {
  border-bottom: 1px solid black !important;
}

.border-top-black {
  border-top: 1px solid black !important;
}

table thead tr.shadow {
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
}

.bg-light-green {
  background-color: #d9ead3;
}

.bg-light-red {
  background-color: #f4cccc;
}

.label {
  text-align: right;
}
