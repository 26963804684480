.comment-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.comment-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.mt-5 {
  margin-top: 2rem;
}
