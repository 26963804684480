.seat-number {
  font-family: 'Helvetica Neue', Arial, Helvetica, Geneva, sans-serif !important;
  font-size: 0.8em !important;
  font-weight: bold !important;
}

.taken {
  color: #dc3545;
}

.taken:hover {
  cursor: not-allowed;
}

.inProgress {
  color: #dcc035;
}

.inProgress:hover {
  cursor: not-allowed;
}

.callCenterReservation {
  color: #dc7b35;
}

.callCenterReservation:hover {
  cursor: not-allowed;
}

.selectedSeat {
  color: #28a745;
}

.specialPriceSeat {
  background-color: #ffd700;
  border: 2px solid #ffa500;
}

.specialPriceSeat .seat-number {
  font-weight: bold;
  color: #000;
}

.special-price-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
  color: red;
}
