/* logo that goes on the nav-bar header */
.header-logo {
  height: 40px;
}

/* header */
header nav.navbar {
  border-top: 2px solid #4818a9 !important;
  background-color: rgba(255, 255, 255, 0.85) !important;
  /*height: 52px !important;*/
  margin-bottom: 20px !important;
}

/* panels */
.card-header {
  background-color: rgba(28, 4, 38, 0.8) !important;
  border-color: rgba(28, 4, 38, 0.8) !important;
  color: white !important;
}

.card.module {
  border-color: #343a40 !important;
  text-align: center;
  height: 250px;
}

.card.module:hover {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}

.card.module:hover a {
  color: white !important;
}
/* cards with class primary */
.card.module.primary {
  border-color: #007bff !important;
  background-color: #007bff !important;
  color: #fff !important;
}

.card.module.primary:hover {
  border-color: #0062cc !important;
  background-color: #0069d9 !important;
  color: white !important;
}

/* links */
.silent,
.silent:hover,
.silent:focus {
  color: #343a40;
  text-decoration: none;
}

.silent.disabled,
.silent.disabled:hover,
.silent.disabled:focus {
  color: gray !important;
  text-decoration: none;
}

.clickable:hover {
  cursor: pointer;
}

.clickable.disabled:hover {
  cursor: not-allowed;
}

.FormButtonGroup {
  text-align: right;
  margin-top: 1rem;
}

.ItinerarySearch {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse;
}
