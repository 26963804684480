.ItinerarySearchResult {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ItinerarySearchResult:hover {
  background-color: rgba(28, 4, 38, 0.8);
  color: #fff;
  cursor: pointer;
}
