@font-face {
    font-family: 'icomoon';
    src:    url('icomoon.eot?31j6ix');
    src:    url('icomoon.eot?31j6ix#iefix') format('embedded-opentype'),
        url('icomoon.ttf?31j6ix') format('truetype'),
        url('icomoon.woff?31j6ix') format('woff'),
        url('icomoon.svg?31j6ix#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-stairs:before {
    content: "\e900";
}
.icon-toilet:before {
    content: "\e901";
}
.icon-coffee:before {
    content: "\e902";
}
.icon-tv:before {
    content: "\e907";
}
.icon-seat-business:before {
    content: "\e903";
}
.icon-seat-first:before {
    content: "\e904";
}
.icon-seat-economy:before {
    content: "\e905";
}
.icon-seat-crew:before {
    content: "\e906";
}
.icon-seat-driver:before {
    content: "\e909";
}

