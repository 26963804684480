.colorPurple {
  color: white;
  background-color: #4818a9;
}

.w10p {
  width: 10%;
}

.w20p {
  width: 20%;
}

.w40p {
  width: 40%;
}

.w60p {
  width: 60%;
}
