.Floor {
  padding: 50px 20px 30px 20px;
  background: url('../../../resources/images/bus.svg');
  background-size: 100% 100%;
  background-clip: padding-box;
  margin: 10px auto;
  display: inline-block;
}

.Floor > div.row > div.col {
  padding: 0 5px;
}
